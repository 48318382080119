<template>
    <BT-Blade-Item
        bladeName="stock-threshold"
        navigation="stock-thresholds"
        title="Stock Threshold"
        :bladesData="bladesData"
        :canEdit="true"
        :canDelete="true"
        :canRefresh="true">
        <template slot-scope="{ item, data }">
            <BT-Field-Select
                navigation="products"
                v-model="item.productID"
                itemText="productName"
                label="Product"
                :isEditing="data.isNew" />

            <BT-Field-Select
                navigation="locations"
                v-model="item.locationID"
                itemText="locationName"
                label="Location"
                :isEditing="data.isNew" />
            
            <BT-Field-Number
                v-model.number="item.lowerThreshold"
                label="Lower Threshold"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Number
                v-model.number="item.upperThreshold"
                label="Upper Threshold"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Number
                v-model.number="item.minimumOrderAmount"
                label="Minimum Order Amount"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Number
                v-model.number="item.orderIncrement"
                label="Order Increment"
                :isEditing="data.isEditing || data.isNew" />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Stock-Threshold-Blade',
    data: function() {
        return {
            msg: null,
        }
    },
    props: {
        bladesData: null
    },
}
</script>